import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit"
import { RootState, AppThunk } from "../../app/store"

export interface DatabaseState {
  // Data rendering
  data: Array<any>;

  // Account control
  type: "user" | "admin"
  name: string
  surname: string
  email: string
  password: string
}

const initialState: DatabaseState = {
  data: [],

  type: "user",
  name: "name",
  surname: "surname",
  email: "initial@email.com",
  password: "password"
}

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
// export const incrementAsync = createAsyncThunk(
//   "counter/fetchCount",
//   async (amount: number) => {
//     const response = await fetchCount(amount)
//     // The value we return becomes the `fulfilled` action payload
//     return response.data
//   },
// )

export const accountSlice = createSlice({
  name: "account",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    setUserType: (state, action) => {
      state.type = action.payload;
    },
    setUserName: (state, action) => {
      state.name = action.payload;
    },
    setUserSurname: (state, action) => {
      state.surname = action.payload;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setPassword: (state, action) => {
      state.password = action.payload;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(incrementAsync.pending, (state) => {
  //       state.status = "loading"
  //     })
  //     .addCase(incrementAsync.fulfilled, (state, action) => {
  //       state.status = "idle"
  //       state.value += action.payload
  //     })
  //     .addCase(incrementAsync.rejected, (state) => {
  //       state.status = "failed"
  //     })
  // },
})

export const { setData, setUserType, setUserName, setUserSurname, setEmail, setPassword } = accountSlice.actions

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectData = (state: RootState) => state.account.data
export const selectUserType = (state: RootState) => state.account.type
export const selectUserName = (state: RootState) => state.account.name
export const selectUserSurname = (state: RootState) => state.account.surname
export const selectEmail = (state: RootState) => state.account.email
export const selectPassword = (state: RootState) => state.account.password

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
// export const incrementIfOdd =
//   (amount: number): AppThunk =>
//   (dispatch, getState) => {
//     const currentValue = selectCount(getState())
//     if (currentValue % 2 === 1) {
//       dispatch(incrementByAmount(amount))
//     }
//   }

export default accountSlice.reducer
