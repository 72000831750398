import axios from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { setEmail, selectEmail, setPassword, selectPassword} from "../../features/account/accountSlice";
import { ToastContainer, toast } from 'react-toastify';
import '../../styles/account.css';
import 'react-toastify/dist/ReactToastify.css';

const Login = () => {
    
    return ( 
    <>
    <div className="account">
      
      <div className="top">
        <div className="logo"></div>
      </div>


      <div className="login-box card">
        <div className="card-body">
          <h5 className="card-title">Secure Login</h5>

          <form>
            <div className="input-box">
              <input type="text" name="" required/>
              <label>Username</label>
            </div>
            <div className="input-box">
              <input type="password" name="" required/>
              <label>Password</label>
            </div>

            <div className='row'>
              <div className='d-grid gap-2 col-md-5'>
                <a href="#_" className="relative inline-flex items-center px-12 py-3 overflow-hidden text-lg font-medium text-indigo-600 border-2 border-indigo-600 rounded-full hover:text-white group hover:bg-gray-50">
                  <span className="absolute left-0 block w-full h-0 transition-all bg-indigo-600 opacity-100 group-hover:h-full top-1/2 group-hover:top-0 duration-400"></span>
                  <span className="absolute right-0 flex items-center justify-start w-10 h-10 duration-300 transform translate-x-full group-hover:translate-x-0">
                    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path>
                    </svg>
                  </span>
                  <span className="relative">Sign in</span>
                </a>
              </div>

              <div className='d-grid gap-2 col-md-7'>
                <p className='small-print'>By logging in, you accept the Terms of Use. </p>
              </div>
            </div>

          </form>
      
        </div>
      </div>

    </div>
    </>
    );
}
 
export default Login;


// <div className="container-fluid text-center">
// <div className="row align-items-start">

//   <div className="col left_box">
//     <h1>Welcome</h1>

//     <div className="d-grid gap-2 col-md-5 mx-auto">
//       <h2>Secured Login</h2>

//       <p>Login with your current username and password</p>

//       <input
//         className="form-control"
//         onInput={onEmailInput}
//         type="email"
//         placeholder="Email" 
//         required/>
//       <div className="invalid-feedback">{emailPrompt}</div>

//       <div className="input-group">
//         <input
//           className="form-control"
//           id="password_input"
//           onInput={onPasswordInput}
//           type={showPassword ? "text" : "password"}
//           placeholder="Password"
//           required
//         />
//         <div className="invalid-feedback">{passwordPrompt}</div>
//       </div>

//       <div className='d-grid gap-2 col'>
//         <button id="login" onClick={attemptLogin} className="btn btn-outline-primary" type="submit">Log in</button>
//       </div>
      
//       <div className='d-grid gap-2 col'>
//         <button onClick={clickRegister} className="btn btn-outline-primary" type="submit"> Register </button>
//       </div>

//       <p style={{marginTop: "20px"}}>By logging in, you accept the Terms of Use</p>

//       <ToastContainer
//       position="bottom-right"
//       autoClose={5000}
//       hideProgressBar={false}
//       newestOnTop={false}
//       closeOnClick
//       rtl={false}
//       pauseOnFocusLoss
//       draggable
//       pauseOnHover
//       theme="light"
//       />

//     </div>

//   </div>

//   <div className="col right_box"></div>

// </div>
// </div>

// // Hooks definitions
// const navigate = useNavigate();
// const dispatch = useDispatch();
// const userEmail = useSelector(selectEmail);
// const userPassword = useSelector(selectPassword);
// const [showPassword, setShowPassword] = useState(false);

// // Validation
// const [emailPrompt, setEmailPrompt] = useState("");
// const [passwordPrompt, setPasswordPrompt] = useState("");

// // Clean state on navigating to login
// useEffect(() => {
//   dispatch(setEmail(""));
//   dispatch(setPassword(""));
// }, []);

// // Validate and save every user search input into the store
// const onEmailInput = (keystroke: any) => {
//   if (keystroke === '') {
//       setEmailPrompt("");
//     } else if (!keystroke.target.checkValidity()) {
//       keystroke.target.classList.add('is-invalid');
//       setEmailPrompt('Please enter a valid email address'); // Create a message <div> for the user if validation fails
//    } else {
//       setEmailPrompt("");
//       keystroke.target.classList.add('is-valid');
//       keystroke.target.classList.remove('is-invalid');
//     }
//     dispatch(setEmail(keystroke.target.value));
//   }
  
// const onPasswordInput = (keystroke: any) => {
//   if (keystroke === '') {
//       setPasswordPrompt("");
//     } else if (keystroke !== '' && keystroke.target.value.trim().length <= 3) {
//       setPasswordPrompt('Please enter a valid password'); // Create a message <div> for the user if validation fails
//       keystroke.target.classList.add('is-invalid');
//    } else {
//       setPasswordPrompt("");
//       keystroke.target.classList.add('is-valid');
//       keystroke.target.classList.remove('is-invalid');
//     }
//     dispatch(setPassword(keystroke.target.value)); 
//   }

// // Functions
// const attemptLogin = async () => {
//   if (!emailPrompt && !passwordPrompt) { // Execute if validation is passed
//     const loginURL = `http://localhost:3001/account/login`;
//     try {
//         const {data} = await axios.request({ // data = res.send that the backend sends to frontend
//         url: loginURL,
//         method: "post",
//         headers: {
//             "content-type" : "application/x-www-form-urlencoded",
//         },
//         data: {
//             "email" : `${userEmail}`,
//             "password" : `${userPassword}`,
//         },
//     });
//     if (data.status === 1) { // If log-in details correct
//       localStorage.setItem("token",data.token) // Store the token in local storage
//       localStorage.setItem("type",data.userType) // Store user type in local storage
      
//       switch (data.userType) { // Send the user to the correct landing page
//         case ("user"):
//           navigate('/user/menu');
//           break;
//         case ("admin"):
//           navigate('/admin/menu');
//           break;
//         }
//     } else {
//       console.log("Incorrect login");

//       // Toast message if email/password combination is wrong
//       toast.error('☹️ Incorrect password', {
//         position: "bottom-right",
//         autoClose: 5000,
//         hideProgressBar: false,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//         progress: undefined,
//         theme: "light",
//         });
//     }
//     } catch (error) {console.log(error);}
//   }
// }

// const clickRegister = () => navigate('/account/register');