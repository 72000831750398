// Node modules
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// Import pages
import Landing from './components/general/Landing';
import Login from './components/account/Login';

function App() {
  return (
    <>

    <Router>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/account/login" element={<Login />} />

        <Route path="*" element={<Landing />} />
      </Routes>
    </Router>

    </>
  )
}

export default App
